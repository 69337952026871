import React from 'react'
import Layout from './src/components/layouts/TransitionLayout'
import './src/styles/global.sass'
import { RecoilRoot } from 'recoil'
import { CookiesProvider } from 'react-cookie'

export const wrapWithPage = ({ element, props }:any) => {
	return <Layout {...props}>{element}</Layout>
}

export const wrapWithProvider = ({ element, props }:any) => {
	return <CookiesProvider>
			<RecoilRoot>{element}</RecoilRoot>
		</CookiesProvider>
}
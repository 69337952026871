import { globalHistory, navigate } from '@reach/router'
import Cookies from 'js-cookie'
import { logout, isBrowser } from '../services/auth'

const IS_DEBUG = false

const setRedirectPath = (location:any) => {
    let redirectPath = location.pathname

    const currentRedirectPath = location.href.replace(/\?.*$/, "");
    //Cookies.get("connect_redirect")?.toString()
    if( !currentRedirectPath && ( redirectPath.match(/404/g) || redirectPath.match(/500/g) || !redirectPath.match(/\/item/g))){
        redirectPath = `/`
    }
    // console.log("setRedirect",currentRedirectPath)
    Cookies.set("connect_redirect", currentRedirectPath || "/test", {path:"/", expires: 30/86400})//3分
    // Cookies.set("connect_redirect", redirectPath, {path:"/", expires: 30/86400})//3分
}

// Hook
const registerHandler = () => {
    const { location } = globalHistory
    if( IS_DEBUG ) {
        window.location.href = `${location.origin}/member/`
        return
    }
    const url = location.href.replace(/\?.*$/, "");
    if (isBrowser()) {
        window.location.href = `${process.env.GATSBY_MODD_URL_REGISTER}${url}`
    }
}

const loginHandler = (isBlank?: boolean) => {
    const { location } = globalHistory
    if( IS_DEBUG ) {
        window.open(`${location.origin}/member/`, '_blank')
        return
    }
    //urlパラメータが含まれていたら削除
    setRedirectPath(location)

    let url = `${location.origin}/connect/`
    if (isBrowser()) {
        if(!isBlank){
            window.location.href = `${process.env.GATSBY_MODD_URL_LOGIN}${url}`
        } else {
            window.open(`${process.env.GATSBY_MODD_URL_LOGIN}${url}`, '_blank')
        }
    }
}

const clickLoginHandler = (isBlank?:boolean) => {
    const { location } = globalHistory
    if( IS_DEBUG ) {
        window.open(`${location.origin}/member/`, '_blank')
        return
    }
    Cookies.set("logined", 'true', {path:"/", expires: 30/86400})//3分
    console.log("pathname",location.pathname)
    setRedirectPath(location)
    
    let url = `${location.origin}/connect/`
    if (isBrowser()) {
        if(!isBlank){
            window.location.href = `${process.env.GATSBY_MODD_URL_LOGIN}${url}`
        } else {
            window.open(`${process.env.GATSBY_MODD_URL_LOGIN}${url}`, '_blank')
        }
    }
}
const logoutHandler = ({cb}:{cb?:()=>void}) => {
    if( IS_DEBUG ) {
        window.location.href = `${location.origin}`
        return
    }
    
    Cookies.remove("logined")
    Cookies.remove("connect_redirect")
    logout({
        callback: () => {
            if(cb){
                cb()
            }
            if (isBrowser()) {
                window.location.href = `${process.env.GATSBY_MODD_URL_LOGOUT}`
            }
        }
    })
}

export { loginHandler, clickLoginHandler, registerHandler, logoutHandler }
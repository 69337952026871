module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"html":{"useGatsbyImage":true,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":true,"placeholderType":"none","gatsbyImageOptions":{"loading":"lazy","placeholder":"none"},"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70},"develop":{"hardCacheData":false,"hardCacheMediaFiles":false,"nodeUpdateInterval":5000},"schema":{"perPage":20,"requestConcurrency":5,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"url":"https://wpec.ssj-shop.net/graphql","type":{"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"hostingWPCOM":false,"useACF":true,"acfOptionPageIds":[],"verboseOutput":true,"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"S.S.Jオンラインショップ","short_name":"S.S.J","start_url":"/","background_color":"#000000","theme_color":"#000000","icon":"static/icon_ec.png","include_favicon":false,"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","cacheDigest":"9aa17b88142cafece115b7203aa5fd9d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PWNR9WKL","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

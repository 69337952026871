import { urlFcMenuList, urlMenuList } from "../functional/urls"
// import { useRecoilState } from "recoil"
// import { userLoginState } from "../../status/UserState"
// import { useEffect } from "react"
import { loginHandler, logoutHandler } from "../../utility/LoginHandler"
import { MyLink } from "../elements/Link"
import { globalHistory } from '@reach/router'
import EnterIcon from "../elements/icons/EnterIcon"
import clsx from "clsx"
import Logos from "../parts/Logos"
// import SwitchLanguageButton from "../elements/buttons/SwitchLanguageButton"
import CartIcon from "../elements/icons/CartIcon"
import { graphql, navigate, useStaticQuery } from "gatsby"
// import { getFcUser, getUser } from "../../services/auth"

interface Props {
}

export default function Header({}:Props){
	const q:{
        cats: WpMenu
    } = useStaticQuery(graphql`
        query {
            cats: wpMenu( slug: {eq: "menu_category"} ) {
				...MenuFragment
			}
        }
    `)
	const { pathname } = globalHistory.location
	// const [userLogin, setUserLogin] = useRecoilState(userLoginState)
	const isMember = true
	let menuList = isMember ? urlFcMenuList : urlMenuList
	const commonClass = "z-50 fixed"
	const blendModeClass = "mix-blend-difference"

	return (
		<header id="header" className="text-[#cccccc]">
			<Logos/>
			<div className={clsx(
				"flex items-center justify-end h-auto w-[calc(100%_-_400px)]",
				"top-[30px] right-11 pc:right-6 z-40 fixed",
				blendModeClass,
			)}>
				<nav className={clsx(
					"font-bold text-base hidden",
					isMember ? "fcnav:block" : "nav:block"
				)}>
					<ul className="flex flex-wrap justify-end items-center gap-x-8 gap-2-y">
						{q.cats.menuItems.nodes.map(({label, path},i)=>{
							return (
								<li key={i}>
									<MyLink to={path}>{label}</MyLink>
								</li>
							)
						})}
						{ menuList.map(({label,to},i)=>{
							let isClick = to === "login" || to === "logout" || label === "CART"
							return (
								<li key={i}>
									{ !isClick && <MyLink to={to}>{label}</MyLink> }
									{ to === "login" &&
										<button onClick={()=> loginHandler(true)} className="flex items-center">
											<span className="mr-2 -mt-[0.1em] [&_path]:fill-[#ccc]">
												<EnterIcon width={24} height={24}/>
											</span>
											{label}
										</button>
									}
									{ label === "CART" &&
										<button onClick={()=> navigate(`${to}${globalHistory.location.href}`)} className="flex items-center">
											<span className="mr-2 -mt-[0.1em] [&_path]:fill-[#ccc]">
												<CartIcon width={24} height={24}/>
											</span>
											{label}
										</button>
									}
									{ to === "logout" &&
										<button onClick={()=>logoutHandler({cb:()=>{}})} className="">
											{label}
										</button>
									}
								</li>
							)
						})}
					</ul>
				</nav>
				{/* <div className="translate nav:ml-6 absolute right-5 top-[2px] -translate-y-1/2 nav:relative nav:top-auto nav:right-auto nav:translate-y-0">
					<SwitchLanguageButton/>
				</div> */}
			</div>
		</header>
	)
}
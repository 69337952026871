import clsx from "clsx"
import { MyLink } from "../elements/Link"
import { globalHistory } from "@reach/router"
import WatanabeLogo from "../elements/logos/WatanabeLogo"
import MainLogo from "../elements/logos/MainLogo"

export default function Logos({
    isBlendMode,
	isModal,
}:{
    isBlendMode?:boolean
	isModal?:boolean
}){
    const { pathname } = globalHistory.location
    const isMember = pathname.match(/member/g)
	const homeUrl = isMember ? "/member/" : "/"
    const commonClass = "z-50 fixed"
	const blendModeClass = "mix-blend-difference"
    return(
        <>
			<MyLink to={homeUrl} className={clsx(
				"block top-5 left-6 w-[64px]",
				!isModal && "tb:top-6 tb:left-6 tb:w-[107px]",
				commonClass
			)}>
				<h1 className={clsx(
					"block",
				)}>
					<MainLogo/>
				</h1>
			</MyLink>
		</>
    )
}
exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-category-index-tsx": () => import("./../../../src/pages/category/index.tsx" /* webpackChunkName: "component---src-pages-category-index-tsx" */),
  "component---src-pages-connect-tsx": () => import("./../../../src/pages/connect.tsx" /* webpackChunkName: "component---src-pages-connect-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-item-article-tsx": () => import("./../../../src/templates/item-article.tsx" /* webpackChunkName: "component---src-templates-item-article-tsx" */),
  "component---src-templates-item-category-list-tsx": () => import("./../../../src/templates/item-category-list.tsx" /* webpackChunkName: "component---src-templates-item-category-list-tsx" */),
  "component---src-templates-topics-article-tsx": () => import("./../../../src/templates/topics-article.tsx" /* webpackChunkName: "component---src-templates-topics-article-tsx" */),
  "component---src-templates-topics-list-tsx": () => import("./../../../src/templates/topics-list.tsx" /* webpackChunkName: "component---src-templates-topics-list-tsx" */)
}


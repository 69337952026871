import React, { createContext, useEffect } from "react"
import { motion, AnimatePresence, Variants } from 'framer-motion'
import { useEventListener } from "usehooks-ts"
import Header from "./Header"
import ModalMenu from "./ModalMenu"
import Footer from "./Footer"
import Loading from "./Loading"
import { useLogin } from "../../hooks/useLogin"

export const TransitionContext:any = createContext(null)
export const useTransition = () => React.useContext(TransitionContext)

const options = {
    duration: 0.6,
    delay: 0.6,
}

export default function TransitionLayout({ children, location }:{
	children: any
	location: any
})
{
    
    useLogin()
    const [first, setFirst] = React.useState(true)
    const [transitionState, setTranstionState] = React.useState(3)
    const key = location.pathname
    const duration = options.duration// + 10
    const delay = options.delay + (first ? 1000.0 : 0)

    const params:Variants = {
        initial: {
            position: 'relative',
            // opacity: 0,
        },
        enter: {
            transition: {
                duration: duration,
                delay: delay,
                when: 'beforeChildren',
            },
            // opacity: 1,
        },
        exit: {
            transition: { duration: duration },
            // opacity: 0,
        },
    }
	console.log("hoge",key)

    useEffect(() => {
        // 0: none, 1:start, 2:loading, 3: complete
        //start with TransitionLink.
        console.log('test',transitionState)
        //init

        if (transitionState === 1) {
            console.log('[[[ Transition <<< Show Start >>> ]]]')
        } else if (transitionState === 2) {
            console.log('[[[ Transition <<< Loading Start >>> ]]]')
        } else if (transitionState === 3) {
            console.log('[[[ Transition <<< Loading Complete >>> ]]]')
            setTranstionState(4)
        } else if (transitionState === 4) {
            console.log('[[[ Transition <<< Hide Start >>> ]]]')
            setTimeout(() => {
                setTranstionState(0)
            }, (options.duration + options.delay) * 1000)
        }
    }, [transitionState])

    //browser back push action
    useEventListener('popstate', () => {
        setTranstionState(0)
    })

    return (
        <TransitionContext.Provider value={{
            transitionState: transitionState,
            setTranstionState: setTranstionState,
            duration: options.duration,
            delay: options.delay,
        }}>
            <div id="root" className="root">
				<Loading/>
				<ModalMenu/>
				<Header/>
                <main className="min-h-[calc(100vh_-_317px)]">
                    <AnimatePresence
                        onExitComplete={() => {
                            if (transitionState === 1) {
                                setTranstionState(2)
                            }
                        }}
                        mode="wait"
                    >
                        <motion.div
                            className="h-"
                            key={key}
                            variants={params}
                            initial="initial"
                            animate="enter"
                            exit="exit"
                            onAnimationStart={() => {
                                if( first === true ){
                                    setFirst(false)
                                    setTranstionState(2)
                                }
                            }}
                            onAnimationComplete={() => {
                                if (transitionState === 2) {
                                    setTranstionState(3)
                                }
                            }}
                        >
                            {/* {children} */}
                        </motion.div>
                    </AnimatePresence>
                    {children}
                </main>
				<Footer/>
            </div>
        </TransitionContext.Provider>
    )
}
